import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"

import styles from "./loginForm.module.scss"
import guardianCounselApi from "../../apis/guardianCounselApi"
import { AuthContext } from "../../context/AuthContext"

const LoginForm = () => {
  const [email, setEmail] = useState("")

  console.log("this is login form")

  useEffect(() => {
    console.log(email)
  }, [email])

  return (
    <>
      {email.length === 0 && <EmailForm setEmail={setEmail} />}
      {email.length > 0 && <TokenForm email={email} />}
    </>
  )
}

const EmailForm = ({ setEmail }) => {
  const [error, setError] = useState("")

  const [submitting, setSubmitting] = useState(false)

  const { register, getValues, handleSubmit } = useForm()

  const formSubmit = async () => {
    const { email } = getValues()
    console.log(email)
    setSubmitting(true)

    try {
      await guardianCounselApi.post("/auth/send2fa", { email })

      setEmail(email)
    } catch (err) {
      setSubmitting(false)

      try {
        setError(err.response.data.errors.Email)
      } catch {
        setError("Error signing in. Please try again later.")
      }
    }
  }

  return (
    <>
      {submitting && <span>Verifying and sending email... </span>}

      <div style={submitting ? { display: "none" } : null}>
        <div className={styles.instructions}>
          Enter your email to be sent a secure authentication code:
        </div>
        <form onSubmit={handleSubmit(formSubmit)}>
          {error.length > 0 && (
            <span className="text-danger">Error: {error}</span>
          )}

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              autoComplete="off"
              ref={register}
              className="form-control"
              type="email"
              id="email"
              name="email"
            />
          </div>

          <button type="submit" className="btn btn-info">
            Send Authentication Code
          </button>
        </form>
      </div>
    </>
  )
}

const TokenForm = ({ email }) => {
  const { login } = useContext(AuthContext)

  const [error, setError] = useState("")

  const [submitting, setSubmitting] = useState(false)

  const { register, getValues, setValue, handleSubmit } = useForm()

  const formSubmit = async () => {
    const { token } = getValues()

    setSubmitting(true)

    try {
      const response = await guardianCounselApi.post("/auth/login2fa", {
        email,
        token,
      })

      login(response.data)

      navigate("/dashboard")
    } catch (err) {
      setValue("token", "")

      const errorMessage = err.response.data.errors.Token

      console.log(errorMessage)

      setError(errorMessage)
      setSubmitting(false)
    }
  }

  return (
    <>
      {submitting && <span>Validating... </span>}
      <div style={submitting ? { display: "none" } : null}>
        <div className={styles.instructions}>
          Enter the authentication code that was sent to {email} to login:
        </div>
        <form onSubmit={handleSubmit(formSubmit)}>
          {error.length > 0 && (
            <span className="has-text-danger">Error: {error}</span>
          )}

          <div className="form-group">
            <label htmlFor="token">Authentication Code</label>
            <input
              autoComplete="off"
              ref={register}
              className="form-control"
              type="text"
              name="token"
              id="token"
            />
          </div>

          <button className="btn btn-success text-light" type="submit">
            Submit & Sign In
          </button>
        </form>
      </div>
    </>
  )
}

export default LoginForm
