import React, { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"
import { useEffect } from "react"
import { navigate } from "gatsby"

const RedirectIfSignedIn = ({ to }) => {
  const { checkSignedIn } = useContext(AuthContext)

  useEffect(() => {
    const runCheck = async () => {
      const isSignedIn = await checkSignedIn()

      if (isSignedIn) {
        navigate(to)
      }
    }

    runCheck()
  }, [])

  return <></>
}

export default RedirectIfSignedIn
